import { notification } from 'antd';
import type { CardProperties } from 'components/revisar/CardList';
import { useLoginStore } from 'features/login/store';
import { canUserListAllVendedores } from 'features/login/util';
import { ApiNotaFiscalStatus } from 'features/nota-fiscal/types/ApiNotaFiscalStatus';
import { type RootDispatch, type RootState, store } from 'state/store';
import { type BuildUrlParams, buildUrl } from 'std/api/buildUrl';
import { comTokenGet, comTokenPost, comTokenPut, comTokenRemove } from 'std/api/comToken';
import type { ApiResponseStatus } from 'std/api/types';
import { isResponseOk, throwIfResponseIsErr } from 'std/api/util';
import { endReduxFnError, endReduxFnOk, startReduxFn } from 'std/redux';
import type { SimNao } from 'std/types';
import { DefaultColors, TipoConsulta } from 'std/types/enum';
import { Endpoint } from 'std/types/enum/endpoint';
import type { RowCupomFiscal } from 'std/types/form/RowCupomFiscal';
import type { SortParams } from 'std/types/interfaces';
import { deepClone } from 'std/util';
import type { TCupomFiscal, TCupomFiscalTotais } from './types';

export const effects = (dispatch: RootDispatch) => ({
    async get(
        payload: {
            pesquisar?: string;
            data_inicio?: string;
            data_fim?: string;
            total_registros?: string;
            registro_inicial?: number;
            qtde_registros?: number;
            sort?: SortParams;
            tipo_consulta?: TipoConsulta;
            idpk?: string;
        },
        state: RootState,
    ): Promise<void> {
        const { cuponsFiscais } = state;
        const { getTable, get } = cuponsFiscais;

        dispatch.cuponsFiscais.setState({
            get: startReduxFn(get.data),
            getTable: {
                ...getTable,
                updateTable: false,
            },
        });

        try {
            const {
                filterDateRange,
                filterPesquisar,
                filterTipo,
                filterFornecedor,
                filterCliente,
                filterNumero,
                filterSerie,
                filterStatus,
                filterVendedor,
                filterCidade,
                filterMarcador,
                registroInitial,
                qtdRegistros,
                sortParams,
                pagination,
                resetPagination,
            } = getTable;

            const {
                pesquisar,
                total_registros,
                registro_inicial,
                qtde_registros,
                sort,
                tipo_consulta = TipoConsulta.Resumida,
                idpk,
                ...restPayload
            } = payload;

            const ambiente = useLoginStore.getState().getEmpresa()?.emp_fiscal_ambiente;

            // MONTANDO OS PARAMETROS OBRIGATÓRIOS
            const params: BuildUrlParams = {
                data_inicio: filterDateRange?.start.format('DD-MM-YYYY'),
                data_fim: filterDateRange?.end.format('DD-MM-YYYY'),
                registro_inicial:
                    registro_inicial !== null && registro_inicial !== undefined
                        ? registro_inicial
                        : registroInitial,
                qtde_registros: qtde_registros || qtdRegistros,
                orderby: 'nof_data_emissao desc, nof_idpk desc',
                empresa_idpk: useLoginStore.getState().empresaIdpk,
                marcadores: filterMarcador,
                tipo_consulta,
                ambiente,
                ...restPayload,
            };

            // CASO HOUVEREM FILTROS OPCIONAIS
            if (filterStatus) {
                params.status = JSON.stringify(filterStatus);
            }

            if (filterTipo) {
                params.tipo = filterTipo.idpk;
            }

            if (!canUserListAllVendedores()) {
                params.vendedor_idpk = useLoginStore.getState().login?.usu_idpk;
            } else if (filterVendedor) {
                params.vendedor_idpk = filterVendedor.idpk;
            }

            if (filterCidade) {
                params.destinatario_cidade = filterCidade?.nome || '';
            }

            if (filterNumero) {
                params.numero = filterNumero;
            }

            if (filterSerie) {
                params.serie = filterSerie;
            }

            if (pesquisar || filterPesquisar) {
                params.pesquisar = pesquisar || filterPesquisar;
            }

            // filtros que usam param 'where'
            let where = '';

            if (filterFornecedor) {
                where = `nof_fornecedor_idpk=${filterFornecedor.idpk}`;
            }

            if (filterCliente) {
                where = `${where.length ? `${where} AND ` : ''}nof_cliente_idpk=${
                    filterCliente.idpk
                }`;
            }

            if (idpk) {
                where = `${where.length ? `${where} AND ` : ''}nof_idpk=${idpk}`;
            }

            if (where.length) {
                params.where = where;
            }

            // CASO TENHA QUE ORDENAR OS DADOS (*OPCIONAL)
            if (sort?.shouldSort || sortParams?.shouldSort) {
                params.orderby = `${sort?.fieldName || sortParams?.fieldName}${
                    sort?.orderDirection || sortParams?.orderDirection
                }`;
            }

            // CASO TIVER ENVIADO PARA MOSTRAR TODOS REGISTROS
            if (total_registros) {
                params.total_registros = total_registros;
            }

            // SE MUDAR O FILTRO OU PRECISAR RESETAR A PAGINAÇÃO
            if (resetPagination) {
                params.registro_inicial = 0;
                params.total_registros = 'S';
            }

            const url = buildUrl(Endpoint.NFCeConsultar, params);
            const response = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const {
                data: { registros = [], total_registros: totalRegistroResponse = null } = {},
            } = response;

            dispatch.cuponsFiscais.setState({
                get: endReduxFnOk(registros),
                getTable: {
                    ...getTable,
                    updateTable: false,
                    ...((totalRegistroResponse || totalRegistroResponse === 0) && {
                        totalRegistrosTable: totalRegistroResponse,
                    }),
                    // SE MUDAR O FILTRO OU PRECISAR RESETAR A PAGINAÇÃO
                    ...(resetPagination && {
                        registro_inicial: 0,
                    }),
                    pagination: {
                        ...pagination,
                        // SE MUDAR O FILTRO OU PRECISAR RESETAR A PAGINAÇÃO
                        ...(resetPagination && {
                            current: 1,
                        }),
                        // SE PRECISAR ATUALIZAR A PÁGINA É FEITO AQUI
                        ...(registro_inicial !== null &&
                            registro_inicial !== undefined && {
                                current: registro_inicial / (qtde_registros || qtdRegistros) + 1,
                            }),
                        // SE PRECISAR ATUALIZAR OS TOTAIS É FEITO AQUI
                        ...((totalRegistroResponse || totalRegistroResponse === 0) && {
                            total: totalRegistroResponse,
                            showTotal: () => `Total de Registros: ${totalRegistroResponse}`,
                            showSizeChanger: totalRegistroResponse > 10,
                        }),
                    },
                    resetPagination: false,
                },
            });
        } catch (error) {
            dispatch.cuponsFiscais.setState({
                get: endReduxFnError(error),
            });
        }
    },

    async totalizador(_, state: RootState): Promise<void> {
        const { cuponsFiscais } = state;
        const { totalizador, getTable } = cuponsFiscais;

        dispatch.cuponsFiscais.setState({
            totalizador: startReduxFn(totalizador.data),
        });

        const {
            filterDateRange,
            filterPesquisar,
            filterTipo,
            filterFornecedor,
            filterCliente,
            filterNumero,
            filterSerie,
            filterVendedor,
            filterCidade,
            filterMarcador,
        } = getTable;

        const ambiente = useLoginStore.getState().getEmpresa()?.emp_fiscal_ambiente;

        const params: BuildUrlParams = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            data_inicio: filterDateRange?.start.format('DD-MM-YYYY'),
            data_fim: filterDateRange?.end.format('DD-MM-YYYY'),
            marcadores: filterMarcador,
            pesquisar: filterPesquisar,
            ambiente,
        };

        if (filterTipo) {
            params.tipo = filterTipo.idpk;
        }

        if (!canUserListAllVendedores()) {
            params.vendedor_idpk = useLoginStore.getState().login?.usu_idpk;
        } else if (filterVendedor) {
            params.vendedor_idpk = filterVendedor.idpk;
        }

        if (filterCidade) {
            params.destinatario_cidade = filterCidade?.nome;
        }

        if (filterNumero) {
            params.numero = filterNumero;
        }

        if (filterSerie) {
            params.serie = filterSerie;
        }

        // filtros que usam param 'where'
        let where = '';

        if (filterFornecedor) {
            where = `nof_fornecedor_idpk=${filterFornecedor.idpk}`;
        }

        if (filterCliente) {
            where = `${where.length ? `${where} AND ` : ''}nof_cliente_idpk=${filterCliente.idpk}`;
        }

        if (where.length) {
            params.where = where;
        }

        const url = buildUrl(Endpoint.NFCeValoresTotais, params);

        try {
            const response = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const {
                data: { registros = [] } = {},
            } = response;

            const cores = {
                Incorreta: DefaultColors.Orange,
                Aguardando: DefaultColors.Blue,
                Emitida: DefaultColors.Green,
                Cancelada: DefaultColors.Red,
                Rejeitada: DefaultColors.Purple,
                Estornada: DefaultColors.Gray,
                Total: DefaultColors.Black,
            };

            const totais: CardProperties[] = registros.map((total: TCupomFiscalTotais) => ({
                color: cores[total.tipo],
                title: total.tipo,
                amount: total.valor_total,
                number: total.quantidade_total,
                value: total.tipo,
            }));

            dispatch.cuponsFiscais.setState({
                totalizador: endReduxFnOk(totais),
            });
        } catch (error) {
            dispatch.cuponsFiscais.setState({
                totalizador: endReduxFnError(error),
            });
        }
    },

    async getOne(payload: { nof_idpk: number }, state: RootState): Promise<TCupomFiscal> {
        dispatch.cuponsFiscais.setState({
            getOne: startReduxFn(state.cuponsFiscais.getOne.data),
        });

        const { nof_idpk } = payload;
        const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };

        const url = buildUrl(Endpoint.NFCeConsultar, params, nof_idpk);

        try {
            const response = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const {
                data: { registros = [] } = {},
            } = response;

            const returnGetOne = registros && registros.length > 0 ? registros[0] : null;

            dispatch.cuponsFiscais.setState({
                getOne: endReduxFnOk(returnGetOne),
            });

            return returnGetOne;
        } catch (error) {
            dispatch.cuponsFiscais.setState({
                getOne: endReduxFnError(error),
            });
            return error;
        }
    },

    async post(
        payload: { body: TCupomFiscal; updateTable?: boolean },
        state: RootState,
    ): Promise<void> {
        dispatch.cuponsFiscais.setState({
            post: startReduxFn(state.cuponsFiscais.post.data),
        });

        try {
            const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };
            const url = buildUrl(Endpoint.NFCeInserir, params);
            const response = await comTokenPost(url, payload.body);
            throwIfResponseIsErr(response);

            const nof_idpk: number = response.data.registros?.[0]
                ? response.data.registros[0].nof_idpk
                : 0;

            dispatch.cuponsFiscais.setState({
                post: endReduxFnOk({ nof_idpk }),
            });

            if (payload?.updateTable) {
                dispatch.cuponsFiscais.get({});
            }

            notification.success({
                message: 'Feito!',
                description: 'Nota Fiscal cadastrada',
            });
        } catch (error) {
            dispatch.cuponsFiscais.setState({
                post: endReduxFnError(error),
            });

            notification.error({
                message: 'Não foi possível cadastrar a nota fiscal!',
                description: error.message,
            });
        }
    },

    async put(
        payload: {
            nof_idpk: number;
            body: TCupomFiscal;
            updateTable?: boolean;
        },
        state: RootState,
    ): Promise<void> {
        dispatch.cuponsFiscais.setState({
            put: startReduxFn(state.cuponsFiscais.put.data),
        });

        try {
            const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };
            const { nof_idpk = 0, body, updateTable } = payload;
            const url = buildUrl(Endpoint.NFCeAlterar, params, nof_idpk);
            const response = await comTokenPut(url, body);
            throwIfResponseIsErr(response);

            dispatch.cuponsFiscais.setState({
                put: endReduxFnOk('Success'),
            });

            notification.success({
                message: 'Feito!',
                description: 'Nota Fiscal atualizada',
            });

            if (updateTable) {
                dispatch.cuponsFiscais.get({});
            }
        } catch (error) {
            dispatch.cuponsFiscais.setState({
                put: endReduxFnError(error),
            });

            notification.error({
                message: 'Não foi possível atualizar a nota fiscal!',
                description: error.message,
            });
        }
    },

    async remove(
        payload: { nof_idpk: number; updateTable?: boolean },
        state: RootState,
    ): Promise<void> {
        const { cuponsFiscais } = state;
        const { getTable } = cuponsFiscais;

        dispatch.cuponsFiscais.setState({
            remove: startReduxFn(state.cuponsFiscais.remove.data),
            getTable: {
                ...getTable,
                loadingTable: true,
            },
        });

        try {
            const { nof_idpk = 0, updateTable } = payload;
            const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };
            const url = buildUrl(Endpoint.NFCeRemover, params, nof_idpk);
            const response = await comTokenRemove(url);
            throwIfResponseIsErr(response);

            dispatch.cuponsFiscais.setState({
                remove: endReduxFnOk('Success'),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            if (updateTable) {
                const {
                    pagination,
                    pagination: { total = 0 },
                    registroInitial = 0,
                } = getTable;

                let isLastPageOnlyOneRegister = false;

                // VERIFICA SE É A ÚLTIMA PÁGINA E TEM APENAS UM ITEM PARA PODER MUDAR DE PÁGINA APÓS DELETAR
                if (total && registroInitial && total - 1 === registroInitial) {
                    isLastPageOnlyOneRegister = true;
                    dispatch.cuponsFiscais.get({
                        total_registros: 'S',
                        registro_inicial: registroInitial - (pagination?.pageSize || 0),
                    });

                    dispatch.cuponsFiscais.totalizador({});
                }

                if (!isLastPageOnlyOneRegister) {
                    dispatch.cuponsFiscais.get({
                        total_registros: 'S',
                    });

                    dispatch.cuponsFiscais.totalizador({});
                }
            }

            notification.success({
                message: 'Feito!',
                description: 'Nota Fiscal excluída.',
            });
        } catch (error) {
            dispatch.cuponsFiscais.setState({
                remove: endReduxFnError(error),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            notification.error({
                message: 'Falhou!',
                description: String(error),
            });
        }
    },

    async cancelarCupomFiscal(
        payload: {
            nof_idpk: number;
            justificativa: string;
            cancelar_venda?: SimNao;
            updateTable?: boolean;
        },
        state: RootState,
    ): Promise<boolean> {
        dispatch.cuponsFiscais.setState({
            cancelarCupomFiscal: startReduxFn(state.cuponsFiscais.cancelarCupomFiscal.data),
        });

        try {
            const { nof_idpk, updateTable, ...rest } = payload;

            const params = {
                ...rest,
                empresa_idpk: useLoginStore.getState().empresaIdpk,
            };

            const url = buildUrl(Endpoint.NFCeCancelar, params, nof_idpk);
            const response = await comTokenPost(url);
            throwIfResponseIsErr(response);

            const { data } = response;

            if (data) {
                const { cuponsFiscais } = state;
                const {
                    getTable,
                    getTable: { selectedRow = undefined },
                } = cuponsFiscais || {};

                if (selectedRow) {
                    const newSelectedRow = deepClone(selectedRow) as RowCupomFiscal;

                    newSelectedRow.nof_nfe_pdf = data.evento_pdf;
                    newSelectedRow.nof_xml = data.evento_xml;

                    dispatch.cuponsFiscais.setState({
                        getTable: {
                            ...getTable,
                            selectedRow: newSelectedRow,
                        },
                    });
                }
            }

            dispatch.cuponsFiscais.setState({
                cancelarCupomFiscal: endReduxFnOk(data),
            });

            if (updateTable) {
                dispatch.cuponsFiscais.get({});
            }

            return true;
        } catch (error) {
            dispatch.cuponsFiscais.setState({
                cancelarCupomFiscal: endReduxFnError(error),
            });

            return false;
        }
    },

    async emitirCupomFiscal(
        payload: {
            itens: RowCupomFiscal[];
            sendToPrint?: boolean;
            updateTable?: boolean;
        },
        state: RootState,
    ): Promise<ApiResponseStatus> {
        dispatch.cuponsFiscais.setState({
            emitirCupomFiscal: {
                data: null,
                error: null,
                loading: true,
                cancel: false,
            },
        });

        const { itens, updateTable } = payload;
        const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };

        let hasError = false;

        const notasComErro: Array<{
            nof_idpk: number;
            nof_numero: number;
            motivoErro: string;
        }> = [];

        const idpksSuccess: number[] = [];

        try {
            // TODO trocar async for por Promisse.All
            // for (const [index, item] of itens.entries()) {
            for (let index = 0; index < itens.length; index += 1) {
                const item = itens[index] as RowCupomFiscal;

                const { cuponsFiscais } = store.getState() as RootState;
                const {
                    emitirCupomFiscal: { cancel = false } = {},
                    getTable,
                } = cuponsFiscais || {};

                if (cancel) {
                    item.emitirCancel = true;

                    dispatch.cuponsFiscais.setState({
                        getTable: {
                            ...getTable,
                            selectedRows: [
                                ...itens.slice(0, index),
                                item,
                                ...itens.slice(index + 1),
                            ],
                        },
                    });

                    hasError = true;

                    throw new Error('Emissão da nota fiscal cancelada pelo usuário');
                }

                item.loading = true;

                dispatch.cuponsFiscais.setState({
                    getTable: {
                        ...getTable,
                        selectedRows: [...itens.slice(0, index), item, ...itens.slice(index + 1)],
                    },
                });

                const url = buildUrl(Endpoint.NFCeEnviar, params, item.key);
                const response = await comTokenPost(url);

                if (!isResponseOk(response)) {
                    const apiErrorMessage = response?.data?.informacao;

                    item.nof_status = 2;
                    item.loading = false;

                    if (apiErrorMessage) {
                        item.motivoErro = apiErrorMessage.map((errors) => errors?.mensagem || '');
                    } else {
                        item.motivoErro = response?.data?.mensagem;
                    }

                    dispatch.cuponsFiscais.setState({
                        getTable: {
                            ...getTable,
                            selectedRows: [
                                ...itens.slice(0, index),
                                item,
                                ...itens.slice(index + 1),
                            ],
                        },
                    });

                    hasError = true;

                    notasComErro.push({
                        nof_idpk: item.nof_idpk || 0,
                        nof_numero: response?.data?.nof_numero || 0,
                        motivoErro: item.motivoErro || '',
                    });

                    continue;
                }

                const {
                    motivo,
                    mensagem = 'Falha ao tentar emitir a nota fiscal!',
                    nof_nfe_pdf = '',
                    nof_xml = '',
                    nof_status = ApiNotaFiscalStatus.Emitida,
                } = response?.data || {};

                if (nof_status !== ApiNotaFiscalStatus.Emitida) {
                    item.nof_status = nof_status;
                    item.loading = false;
                    item.motivoErro = motivo || mensagem;

                    dispatch.cuponsFiscais.setState({
                        getTable: {
                            ...getTable,
                            selectedRows: [
                                ...itens.slice(0, index),
                                item,
                                ...itens.slice(index + 1),
                            ],
                        },
                    });

                    notasComErro.push({
                        nof_idpk: item.nof_idpk || 0,
                        nof_numero: response?.data?.nof_numero || 0,
                        motivoErro: item.motivoErro || '',
                    });

                    hasError = true;

                    continue;
                }

                item.nof_nfe_pdf = nof_nfe_pdf;
                item.nof_xml = nof_xml;
                item.nof_status = nof_status;
                item.loading = false;
                item.emitirOk = true;

                dispatch.cuponsFiscais.setState({
                    getTable: {
                        ...getTable,
                        selectedRows: [...itens.slice(0, index), item, ...itens.slice(index + 1)],
                    },
                });

                idpksSuccess.push(item.key);
            }

            const { cuponsFiscais, vendasExternas } = state;
            const { showDrawerEmitirNf, getTable } = cuponsFiscais || {};

            if (showDrawerEmitirNf || vendasExternas.showDrawerEmitirNf) {
                dispatch.cuponsFiscais.setState({
                    emitirCupomFiscal: {
                        data: hasError ? notasComErro : 'ok',
                        loading: false,
                        error: hasError ? 'Houve um erro em alguma das notas' : null,
                        cancel: false,
                    },
                });
            } else {
                const ajustRowsSelected = itens
                    .filter((row) => !row.emitirOk)
                    .map((row) => {
                        row.emitirCancel = false;
                        row.emitirOk = false;
                        row.motivoErro = undefined;
                        row.loading = false;
                        return row;
                    });

                dispatch.cuponsFiscais.setState({
                    emitirCupomFiscal: {
                        data: 'ok',
                        loading: false,
                        error: null,
                        cancel: false,
                    },
                    getTable: {
                        ...getTable,
                        selectedRows: ajustRowsSelected,
                    },
                });
            }

            if (updateTable) {
                dispatch.cuponsFiscais.get({});
            }

            return 'sucesso';
        } catch (error) {
            dispatch.cuponsFiscais.setState({
                emitirCupomFiscal: {
                    data: 'ok',
                    loading: false,
                    error,
                    cancel: false,
                },
            });

            return 'erro';
        }
    },

    async compartilharSend(
        payload: {
            idpk: number;
            tipo: string;
            template_id: string;
            tags?: { nome: string; valor?: string }[];
            fin_email?: string | null;
            fin_email_cc?: string;
        },
        state: RootState,
    ): Promise<void> {
        dispatch.cuponsFiscais.setState({
            compartilharSend: startReduxFn(state.cuponsFiscais.compartilharSend.data),
        });

        const { idpk, tags, fin_email, fin_email_cc, ...rest } = payload;

        const params = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            ...rest,
        };
        const bodySend = payload.tipo !== 'W' ? { tags, fin_email, fin_email_cc } : undefined;

        const url = buildUrl(Endpoint.NFCeCompartilhar, params, idpk);

        try {
            const response = await comTokenPost(url, bodySend);
            throwIfResponseIsErr(response);

            dispatch.cuponsFiscais.setState({
                compartilharSend: endReduxFnOk(null),
                showDrawerCompartilhar: false,
            });

            if (payload.tipo === 'E') {
                notification.success({
                    message: 'Email enviado',
                    description:
                        'Atenção, a notificação pode demorar até 5 minutos para ser entregue.',
                });
            }
        } catch (error) {
            dispatch.cuponsFiscais.setState({
                compartilharSend: endReduxFnError(error),
            });

            if (payload.tipo === 'E') {
                notification.error({
                    message: 'Falhou!',
                    description:
                        (error as { message: string })?.message || 'Não foi possível enviar!',
                });
            }
        }
    },

    async lançarFinanceiro(payload: {
        idpk: number;
        movimentar?: string;
        estornar?: string;
    }): Promise<void> {
        dispatch.cuponsFiscais.setState({
            lancarFinanceiro: startReduxFn(),
        });

        const { idpk, movimentar, estornar } = payload;

        let params: { empresa_idpk: number; movimentar?: string; estornar?: string } = {
            empresa_idpk: useLoginStore.getState().empresaIdpk || 0,
        };

        if (movimentar) {
            params = { ...params, movimentar };
        } else if (estornar) {
            params = { ...params, estornar };
        }

        const url = buildUrl(Endpoint.NFCeFinanceiro, params, idpk);

        try {
            const response = await comTokenPut(url, null);
            throwIfResponseIsErr(response);

            dispatch.cuponsFiscais.setState({
                lancarFinanceiro: endReduxFnOk(null),
            });

            notification.success({
                message: 'Sucesso!',
            });
        } catch (error) {
            dispatch.cuponsFiscais.setState({
                lancarFinanceiro: endReduxFnError(error),
            });

            notification.error({
                message: 'Falhou!',
                description: String(error),
            });
        }
    },

    async lançarEstoque(payload: {
        idpk: number;
        movimentar?: string;
        estornar?: string;
    }): Promise<void> {
        dispatch.cuponsFiscais.setState({
            lancarEstoque: startReduxFn(),
        });

        const { idpk, movimentar, estornar } = payload;

        let params: { empresa_idpk: number; movimentar?: string; estornar?: string } = {
            empresa_idpk: useLoginStore.getState().empresaIdpk || 0,
        };

        if (movimentar) {
            params = { ...params, movimentar };
        } else if (estornar) {
            params = { ...params, estornar };
        }

        const url = buildUrl(Endpoint.NFCeEstoque, params, idpk);

        try {
            const response = await comTokenPut(url, null);
            throwIfResponseIsErr(response);

            dispatch.cuponsFiscais.setState({
                lancarEstoque: endReduxFnOk(null),
            });

            notification.success({
                message: 'Sucesso!',
            });
        } catch (error) {
            dispatch.cuponsFiscais.setState({
                lancarEstoque: endReduxFnError(error),
            });

            notification.error({
                message: 'Falhou!',
                description: String(error),
            });
        }
    },
});
