import { Modal, notification } from 'antd';
import Bowser from 'bowser';
import clone from 'clone';
import { PurpleText } from 'components/revisar/tipografia/PurpleText';
import dayjs from 'dayjs';
import { nanoid } from 'nanoid';
import type { InternalNamePath } from 'rc-field-form/es/interface';
import { DATE_FORMAT } from 'std/const/date';
import { DEBOUNCE_TIME } from 'std/const/debounceTime';
import { type AsyncResult, err, ok } from 'std/rusty/result';
import { removerAcento } from 'std/string/removerAcento';
import { isDate } from 'std/validate';

/** Utilizado na store do zustand para aplicação de filtros */
export function applyFilter<
    Filter extends object,
    State extends { filter: Optional<Partial<Filter>> },
>(
    filter: Optional<Partial<Filter>>,
    get: () => State,
    set: (newState: { filter: Optional<Partial<Filter>> }) => void,
): void {
    const newFilter = {
        ...get().filter,
        ...filter,
    };

    const allUndefined =
        Object.keys(newFilter).length === 0 ||
        Object.keys(newFilter).every((key) => !newFilter[key]);

    if (allUndefined) {
        set({ filter: undefined });
    } else {
        set({ filter: newFilter });
    }
}

/** Filtra por valores únicos. Usado com o 'Array.prototype.filter' */
export function arrayFilterUnique(
    value: string | number,
    index: number,
    self: (string | number)[],
): boolean {
    return self.indexOf(value) === index;
}

export function changeFavIcon(href: string): void {
    let linkFavicon = document.querySelector("link[id~='favicon']") as HTMLLinkElement;

    if (!linkFavicon) {
        linkFavicon = document.createElement('link');
        linkFavicon.rel = 'icon';
        document.head.appendChild(linkFavicon);
    }

    linkFavicon.href = href;

    let linkFavicon2 = document.querySelector("link[id~='favicon2']") as HTMLLinkElement;

    if (!linkFavicon2) {
        linkFavicon2 = document.createElement('link');
        linkFavicon2.rel = 'icon';
        document.head.appendChild(linkFavicon2);
    }

    linkFavicon2.href = href;

    let linkFavicon3 = document.querySelector("link[id~='favicon3']") as HTMLLinkElement;

    if (!linkFavicon3) {
        linkFavicon3 = document.createElement('link');
        linkFavicon3.rel = 'icon';
        document.head.appendChild(linkFavicon3);
    }

    linkFavicon3.href = href;

    let linkFavicon4 = document.querySelector("link[id~='favicon4']") as HTMLLinkElement;

    if (!linkFavicon4) {
        linkFavicon4 = document.createElement('link');
        linkFavicon4.rel = 'icon';
        document.head.appendChild(linkFavicon4);
    }

    linkFavicon4.href = href;

    let linkFavicon5 = document.querySelector("link[id~='favicon5']") as HTMLLinkElement;

    if (!linkFavicon5) {
        linkFavicon5 = document.createElement('link');
        linkFavicon5.rel = 'icon';
        document.head.appendChild(linkFavicon5);
    }

    linkFavicon5.href = href;
}

export function copyTextToClipboard(text: string): void {
    navigator.clipboard.writeText(text);
}

export function openNewTab(url: string): void {
    window.open(url, '_blank');
}

/** Atrasa a execução de uma função conforme o delay informado. */
export function debounce(fn: (...args: any[]) => void, delay: number = DEBOUNCE_TIME) {
    let timerId: NodeJS.Timeout;

    return (...args: any[]) => {
        if (!timerId) {
            fn(...args);
        }

        clearTimeout(timerId);
        timerId = setTimeout(() => fn(...args), delay);
    };
}

export function dbg(obj: Record<string, any>): void {
    const stack = new Error().stack?.split('\n');
    console.debug('[DEBUG]', obj, { stack });
}

export function deepClone<T>(value: T): T {
    return clone(value);
}

export function getEnumText(
    someEnum: Record<string | number, number | string>,
    enumNumber: number,
): string {
    const enumKey = Object.keys(someEnum).find((key) => someEnum[key] === enumNumber);
    return enumKey ? enumKey.toString() : '';
}

/** Função utilizada para baixar arquivos csv / xml */
export function downloadTextFile(value: string | string[], fileName: string): void {
    const blob = new Blob(
        [
            new Uint8Array([0xef, 0xbb, 0xbf]), // UTF-8 BOM
            ...value,
        ],
        { type: 'text/plain;charset=utf-8' },
    );

    const url = window.URL.createObjectURL(blob);
    downloadFile(url, fileName);
}

export function downloadFile(url: string, fileName: string): void {
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);

    downloadLink.href = url;
    downloadLink.target = '_self';
    downloadLink.download = fileName;
    downloadLink.click();
}

export function filterOption(
    input: string,
    option: Optional<{
        value?: string | number | null;
        label?: React.ReactNode;
    }>,
): boolean {
    if (!option?.label) {
        return false;
    }

    const opt = typeof option.label === 'string' ? option.label : option.label.toString();
    const opcao = removerAcento(opt.toLowerCase());
    const valor = removerAcento(input.toLowerCase());

    return opcao.includes(valor);
}

export function omit<T extends object, K extends keyof T>(obj: T, keys: K[]): Omit<T, K> {
    const result: T = { ...obj };

    for (const key of keys) {
        delete result[key];
    }

    return result;
}

export function pick<T extends object, K extends keyof T>(obj: T, keys: K[]): Pick<T, K> {
    const result: any = {};

    for (const key of keys) {
        result[key] = obj[key];
    }

    return result;
}

// biome-ignore lint/complexity/noExcessiveCognitiveComplexity: gerador pelo chatgpt 4
export function replaceUndefinedWithNull(obj: Record<string, any>) {
    // Check if the current item is an array
    if (Array.isArray(obj)) {
        // Iterate over each item in the array
        for (let i = 0; i < obj.length; i++) {
            // Replace undefined with null if the item is undefined
            if (obj[i] === undefined) {
                obj[i] = null;
            } else if (typeof obj[i] === 'object' && obj[i] !== null) {
                // Recursively call the function if the item is an object or array
                replaceUndefinedWithNull(obj[i]);
            }
        }
    } else if (typeof obj === 'object' && obj !== null) {
        // Iterate over each key in the object
        for (const key in obj) {
            if (Object.hasOwn(obj, key)) {
                // Replace undefined with null if the property is undefined
                if (obj[key] === undefined) {
                    obj[key] = null;
                } else if (typeof obj[key] === 'object' && obj[key] !== null) {
                    // Recursively call the function if the property is an object or array
                    replaceUndefinedWithNull(obj[key]);
                }
            }
        }
    }
}

export function todo(msg: string): void {
    console.debug('[TODO]', msg);

    notification.info({
        message: 'Desculpe, este recurso não está disponível',
        description: msg,
    });
}

export function sortColumn<T>(a: T, b: T, field: string): number {
    const field1 = a[field];
    const field2 = b[field];

    if (typeof field1 === 'string' && typeof field2 === 'string') {
        if (isDate(field1) && isDate(field2)) {
            if (field1 === field2) {
                return 0;
            }

            const data1 = dayjs(field1, DATE_FORMAT);
            const data2 = dayjs(field2, DATE_FORMAT);

            return data1.unix() - data2.unix() < 0 ? -1 : 1;
        }

        return field1.localeCompare(field2);
    }

    if (typeof field1 === 'number' && typeof field2 === 'number') {
        return field1 - field2;
    }

    return 0;
}

export function messageErrorRequiredFields(
    errors: {
        name?: InternalNamePath;
        errors: string[];
    }[],
): void {
    const style = {
        color: '#f5222e',
        fontFamily: 'SimSun, sans-serif',
    };

    const fields = errors.map((field) => field.errors[0] || '');

    Modal.error({
        title: 'Falha na validação dos campos obrigatórios',
        content: (
            <div className='text-justify'>
                <PurpleText>
                    Os campos sinalizados com ( <PurpleText style={style}>*</PurpleText> ) são
                    obrigatórios, e os destacados em ({' '}
                    <PurpleText style={style}>vermelho</PurpleText> ) necessitam atenção. Verifique
                    para continuar.
                </PurpleText>

                <p className='mt-3 font-bold'>Erros:</p>

                {fields.map((field) => (
                    <li key={nanoid()}>
                        <div className='font-bold'>{field}</div>
                    </li>
                ))}
            </div>
        ),
        zIndex: 1500,
    });
}

export async function getGeoLocation(): AsyncResult<
    { latitude: number; longitude: number },
    string
> {
    if (!navigator.geolocation) {
        return err('Navegador não suporta geolocalização');
    }

    const promise: Promise<GeolocationPosition> = new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
    });

    try {
        const { coords } = await promise;
        return ok({ latitude: coords.latitude, longitude: coords.longitude });
    } catch {
        return err('Falha ao obter dados de geolocalização');
    }
}

export async function getIp(): AsyncResult<string, string> {
    try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = (await response.json()) as { ip: string };
        return ok(data.ip);
    } catch {
        return err('Falha ao obter dados de IP');
    }
}

export function getOs(): { os: string; version: string } {
    const browser = Bowser.parse(window.navigator.userAgent);
    return {
        os: browser.os.name || '',
        version: browser.os.versionName || '',
    };
}
